.container {
    width: 100%;
    height: 100%;
    color: var(--white-color);
}

.title {
    color: var(--text-color-secondary);
    font-size: 2rem;
    font-weight: 600;
    margin-top: 7rem;
}

.rows {
    margin-top: 3rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: .6rem;
    height: 29.5rem;
    overflow-y: auto;
}

.row {
    border: 0.1rem solid var(--cell-border);
    border-radius: 0.9rem;
    width: 100%;
    padding: 1.25rem;
}

.r1 {
    display: flex;
    align-items: center;
}

.tokenIcon {
    width: 2.3rem;
    height: 2.3rem;
    margin-right: 0.25rem;
}

.tokenName {
    font-size: 1.4rem;
    font-weight: 600;
    margin-left: 0.25rem;
}

.fillSpace {
    flex: 1;
}

.expandIcon {
    width: 2.5rem;
    height: 2.5rem;
    fill: var(--white-color);
    cursor: pointer;
}

.r2 {
    display: flex;
    font-size: 1.3rem;
    font-weight: 600;
    align-items: center;
}

.detail {
    margin-top: 2.2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.tokenIcon2 {
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
}

.btns {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.btn {
    width: 16rem;
    height: 3.5rem;
    border: none;
    border-radius: 1.1rem;
    color: var(--text-on-accent);
    font-size: 1.6rem;
    font-weight: 500;
    background-color: var(--btn-bg-color);
    background-image: var(--btn-bg-image);
    cursor: pointer;
}

.btn:disabled {
    background-color: var(--btn-bg-dis);
    cursor: default;
}

.importpool {
    font-size: 1.3rem;
}

.link {
    color: var(--accent-color);
    text-decoration: underline;
    cursor: pointer;
}