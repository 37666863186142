.chart {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}
.iframe{
    height: 100%;
    width: 100%;
    border: none;
}