.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.title {
    margin-top: 1.5rem;
    margin-bottom: 3.4rem;
    color: var(--text-color-secondary);
    font-weight: 600;
    font-size: 2rem;
}

.back {
    fill: var(--white-color);
    margin-top: 4.4rem;
    margin-left: -1.5rem;
    cursor: pointer;
    width: 0.8rem;
}

.tokenRow {
    height: 6.4rem;
    width: 44rem;
    background-color: var(--white-color);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    align-self: center;
    position: relative;
}

.tokenIcon {
    height: 2.6rem;
    
}

.tokenName {
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--background-primary-main);
    cursor: pointer;
}

.fillSpace {
    flex: 1;
}

.expandIcon {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}

.circleIcon {
    height: 3.2rem;
    width: 3.2rem;
    fill: var(--accent-color);
    cursor: pointer;
}

.r1 {
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.lpLabel{
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 1.8rem;
    margin-bottom: 1.2rem;
    width: 44rem;
    align-self: center;
    text-align: left;
}
.lprow{
    width: 44rem;
    align-self: center;
    background-color: var(--white-color);
    height: 5.4rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
}
.tkn2{
    flex: 1;
    text-align: center;
    font-size: 1.7rem;
    font-weight: 500;
    color: var(--background-primary-main);
}
.btnAddLp{
    border: none;
    background-color: var(--background-primary-main);
    border-radius: 0.5rem;
    width: 8.8rem;
    height: 3.3rem;
    font-size: 1.3rem;
    color: var(--white-color);
    cursor: pointer;
}

.tokenIcon1 {
    height: 3rem;
}
.tokenIcon2 {
    height:3rem;
    position: relative;
    left: -1.3rem;
}
.options {
    border-radius: 0.5rem;
    position: absolute;
    background-color: var(--selector-bg);
    top: 6.5rem;
    right: 0;
    width: 40%;
    z-index: 2;
    overflow: auto;
    height: 17rem;
}

.option {
    height: 6rem;
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--background-primary-main);
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-left: 2rem;
}
.deactiveToken{
    color: var(--deactive-token);
}
.error{
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 1.8rem;
    margin-bottom: 1.2rem;
    width: 44rem;
    align-self: center;
    text-align: left;
    color: var(--error-color);
}