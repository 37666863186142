.base-btn {
    width: 19rem;
    height: 3.5rem;
    background-color: var(--btn-bg-color);
    background-image: var(--btn-bg-image);
    color: var(--text-on-accent);
    border-radius: 1.1rem;
    font-size: 1.6rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
}

.base-btn:disabled {
    background-color: var(--btn-bg-dis);
    cursor: default;
}

.connect-wallet {
    composes: base-btn;
    width: 22.2rem;
    height: 4.9rem;
    margin-right: 2.5rem;
    font-weight: 600;
}

.approve {
    composes: base-btn;
}

.liquidate {
    composes: base-btn;
    width: 11.89824rem;
    height: 2.59968rem;
    font-size: 1.4rem;
}

.open-position {
    composes: base-btn;
    width: 48.399359999999994rem;
    height: 5.8982399999999995rem;
    font-size: 2.2rem;
    margin-left: auto;
    box-shadow: var(--box-shadow);
}

.ads {
    composes: base-btn;
    width: 22.5rem;
    height: 4.9rem;
    font-weight: 600;
    margin-top: 6rem;
}

.ads-small {
    composes: ads;
    margin-top: 0;
}

@media screen and (max-width: 450px) {
    .open-position {
        width: 100%;
    }

    .connect-wallet {
        width: 19.7rem;
        height: 4.1rem;
        border-radius: 2.1rem;
        font-size: 1.2rem;
        margin-right: 1.5rem;
    }
}