
.fullContainer
{
   display: flex;
   flex-direction: column;
   height: 100vh
}


.row {
  height: calc(100vh - 8rem   - 1px);
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.content {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--background-primary-gradient);
}

@media screen and (max-width: 450px) {
  .row {
    height: auto;
  }

  .content {
    margin-bottom: 9rem;
  }
}
