.slippage {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0.5rem;
    width: 20rem;
    position: absolute;
    background-color: var(--background-primary-main);
    padding: 1.5rem 2.5rem;
    bottom: 0;
    right: 4rem;
    border-radius: 1rem;
    border: 0.1rem solid var(--accent-color);
    overflow: hidden;
}

.slippageInput {
    width: 100%;
    height: 4rem;
    font-size: 1.5rem;
    border: none;
    overflow: hidden;
    border-radius: 0.5rem;
    padding-right: 6rem;
}

.slippageInput:focus {
    outline-width: 0;
}

.changeBtn {
    background-color: var(--btn-bg-color);
    background-image: var(--btn-bg-image);
    color: var(--text-on-accent);
    position: absolute;
    bottom: 1.5rem;
    right: 2rem;
    width: 5rem;
    height: 4rem;
    font-size: 1rem;
    border: none;
    padding: 0;
    cursor: pointer;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}