.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--modal-bg);
    z-index: 10;
    color: var(--text-color-secondary);
}

.modalContent {
    position: absolute;
    top: 8rem;
    right: 2.5rem;
    width: 39.6672rem;
    border-radius: 3.1872rem;
}

.header {
    background: var(--accent-color);
    padding: 2.4rem;
    color: var(--text-on-accent);
    border-top-left-radius: 3.1872rem;

    border-top-right-radius: 3.1872rem;

    font-size: 2.112rem;
    font-weight: 700;

    display: flex;
    align-items: center;
}

.body {
    background-color: var(--background-primary-main);
    border-bottom-left-radius: 3.1872rem;

    border-bottom-right-radius: 3.1872rem;

    padding-bottom: 1.92rem;
    display: flex;
    flex-wrap: wrap;
}

.col {
    width: 50%;
    text-align: center;
    padding-top: 1.92rem;
    cursor: pointer;


}

.txt {
    font-size: 1.4976rem;
    font-weight: 700;
}

.meta {
    width: 5.76rem;
    height: 5.76rem;

}

@media screen and (max-width: 450px) {
    .header{
        padding: 1rem 2rem;
        font-size: 1.8rem;
    }
    .txt{
        font-size: 1rem;
    }
    .modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modalContent {
        position: static;
        width: 80%;
        margin: auto;

    }

    .meta {
        width: 3.5rem;
        height: 3.5rem;

    }
}