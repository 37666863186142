.container {
    min-height: calc(100vh - 14.6rem);
    position: relative;
}

.tabTitles {
    margin-left: 5.45rem;
    font-size: 2rem;
    font-weight: 300;
    display: flex;
    gap: 6.2rem;
    color: var(--white-color);
    cursor: pointer;
}

.tabTitleSelected {
    font-weight: 600;
}

.tabLine {
    width: 10.7rem;
    height: 0.4rem;
    background-color: var(--accent-color);
    border-radius: 0.4rem;
    margin-top: 1.9rem;
    transition: all 600ms;
    margin-left: 7.45rem;
}

.tab2Selecteden {
    margin-left: 27rem;
}
.tab2Selected {
    margin-left: 31rem;
}

.title {
    height: 6.5rem;
    background-color: var(--background-secondary);
    color: var(--text-color-main);
    font-size: 2rem;
    font-weight: 500;
    padding-left: 9rem;
    display: flex;
    align-items: center;
    margin-top: 2.9rem;
}
.emptyList{
    margin-left: 9.1rem;
    margin-top: 3.9rem;
    color: var(--white-color);
    font-size: 2rem;
    font-weight: 500;
}
.loading{
    height: 4rem;
}
.loadingParent{
    margin-top: 2rem;
    text-align: center;
}