.container {
    width: 100%;

}

.header {
    padding: 6rem 5rem 8rem 5rem;
    font-size: 3.5rem;
    color: var(--white-color);
    font-weight: 500;
}

.detail {
    font-size: 2.1rem;
    font-weight: 400;
    margin-top: 1.9rem;
}

.row1 {
    color: var(--text-color-main);
    display: flex;
    height: 12.3rem;
    margin-top: 4.6rem;
    font-size: 2rem;
    font-weight: 400;
}

.col1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sp {
    margin-left: 3rem;
    margin-right: 3rem;
}

.volume {
    font-size: 4.2rem;
    font-weight: 700;
    color: var(--accent-color);
}

@media screen and (max-width: 450px) {
    .header {
        padding: 3rem 2rem;
    }
    .detail{
        font-size: 1.3rem;
    }
    .row1 {
        flex-wrap: wrap;
        font-size: 1.1rem;
        row-gap: 3rem;
        height: auto;
    }

    .col1 {
        flex: auto;
        width: 23.2rem;
    }

    .volume {
        font-size: 2.6rem;
    }

    .sp {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .sp2 {
        display: none;
    }
}