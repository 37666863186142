.container {
    width: 100%;
}

.row1 {
    width: 100%;
    height: 100rem;
    display: flex;
    align-items: stretch;
}

.col11 {
    width: 37%;
}

.col12 {
    flex: 1
}

.row121 {
    height: 36.5rem;
    display: flex;
}

.row122 {
    flex: 1;
    height: 63.3rem;
    width: 100%;
}

.col1211 {
    width: 50%;
}

.col1212 {
    flex: 1;
}

.marketContainer {
    border-left: var(--cell-border);
}

@media screen and (max-width: 450px) {
    .col11 {
        width: 100%;
    }

    .row122 {
        height: 52rem;
    }

    .lq {
        height: 32rem;
    }
}