.container {
    width: 100%;
    height: 100%;
    background-image: url('/src/_customization/assets/fee-bg.jpg');
    background-repeat:no-repeat;
    background-size: 100% 100%;
    padding: 7rem;
}

.smallContainer {
    width: 100%;
    height: 18rem;
    background-image: url('/src/_customization/assets/fee-bg2.jpg');
    background-repeat:no-repeat;
    background-size: 100% 100%;
    padding: 1.5rem 5rem;
    overflow: hidden;
}

.title {
    font-size: 4.2rem;
    font-weight: bold;
    color: var(--white-color);
}
.titleColor{
    color: var(--accent-color);
}
.subTitle{
    color: var(--text-color-main);
    font-size: 1.6rem;
    margin-top: 1.7rem;
    width: 41rem;
}

@media screen and (max-width: 450px) {
    .container {
        padding: 3.5rem;
    }
}