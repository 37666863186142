.container {
    background-color: var(--white-color);
    position: fixed;
    top: 8rem;
    z-index: 10;
    right: 0rem;
    transition: all 1s;
    overflow: hidden;
    width: 50rem;
    border-radius: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    right: -50rem;
}
.show {
    right: 0;
}
.c1{
    background-color: var(--accent-color);
}
.icon1{
    width: 5rem;
    margin: 3rem;
    fill: var(--toaster-icon-success);
}
.icon2{
    width: 2rem;
    margin: 3rem;
    cursor: pointer;
}
.content{
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--accent-color);
}
.link{
    margin-top: 0.5rem;
    font-size: 2rem;
    color: var(--accent-color);
    cursor: pointer;
}