.box {
    padding: 2.3rem 0.6rem 2.3rem 2.3rem;
    height: 100%;
    position: relative;
    overflow: hidden;
    color: var(--text-color-secondary);
}

.row1 {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0.5rem;
    font-size: 1.3rem;
}



.headerRow {
    margin-top: 4.3rem;
    width: 100%;
    gap: 10rem;
    display: flex;
    justify-content: center;
}

.hr {
    background-color: var(--accent-color);
    height: 0.4rem;
    width: 11rem;
    position: relative;
    border: none;
    transition: all 0.5s;
    left: 0;
}

.headerCol {
    width: 13.5rem;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 600;
    color: var(--white-color);
}

.withdrawSelect {
    left: 23.5rem;
}

.unselected {
    font-weight: 300;
}



.hide {
    visibility: hidden;
}

.inputParent {
    margin-top: 2.9rem;
    text-align: center;
    position: relative;
}

.maxBtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 7rem;
    background-color: var(--accent-color);
    color: var(--text-on-accent);
    font-size: 1.6rem;
    font-weight: 500;
    height: 4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.5rem;
    border: none;
}

.input {
    position: relative;
    background-color: var(--text-color-main);
    border-radius: 0.5rem;
    border: none;
    width: 100%;
    height: 6rem;
    text-align: left;
    color: var(--background-primary-main);
    padding-left: 1rem;
    padding-right: 10rem;
}

.input:focus {
    outline-width: 0;
}

.buttonParent {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2.6995199999999997rem;
    align-items: center;
}

.usdtBalance {
    font-size: 1.5rem;
    font-weight: 500;
}

.image {
    width: 12.758399999999998rem;
    height: 12.758399999999998rem;
    right: -2.92rem;
    top: -2.92rem;
    position: absolute;
}





@media screen and (max-width: 450px) {
    .box {
        padding: 2.3rem 2.3rem 2.3rem 2.3rem;
        background-color: var(--background-primary-light);
    }




}