.container {
    width: 100%;
    color: var(--text-color-main);
    display: flex;
}

.tableBody {
    flex: 1;
}

.row {
    height: 9.7rem;
    padding-left: 9rem;
    padding-right: 9rem;
    display: flex;
    gap: 10rem;
    align-items: center;
}

.col {
    width: 19rem;
    font-size: 2rem;
    font-weight: 500;
}

.header {
    height: 6.5rem;
    background-color: var(--background-secondary);
}

.icon {
    width: 4.7rem;
    height: 4.7rem;
}

.col1 {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}



.changeParent {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.pnIcon {
    width: 1.5rem;
}

.neg {
    font-weight: 600;
    color: var(--negative-growth);
    fill: var(--negative-growth);
}

.pos {
    font-weight: 600;
    color: var(--positive-growth);
    fill: var(--positive-growth);
}

.row1 {
    display: flex;
    gap: 1rem;
}

.symbolBox {
    color: var(--background-secondary);
    background-color: var(--token-box-bg);
    padding-left: 1rem;
    padding-right: 1rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.fontWeight400 {
    font-weight: 400;
}

.pageNav {
    display: none;
}

.pageNavIcon {
    width: 1.5rem;
    fill: var(--white-color);
    cursor: pointer;
}

@media screen and (max-width: 450px) {
    .container {
        padding-left: 2.5rem;
        padding-right: 2rem;
    }

    .row {
        padding-left: 0;
        padding-right: 0;
        gap: 1rem;
        height: 8rem;
    }

    .col {
        font-size: 1.6rem;
        width: 15rem;
    }

    .icon {
        width: 2.4rem;
        height: 2.4rem;
    }

    .col1 {
        font-size: 1.26rem;
    }

    .symbolBox {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: 1.7rem;

    }

    .pageNav {
        display: flex;
        align-items: center;
        justify-content: right;
        width: 6rem;
    }

}