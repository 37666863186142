html {
  /*font-size: 10px;*/
  font-size: 0.5208vw;
  background-color: var(--background-primary-main);
}

body {
  margin: 0;
}

* {
  font-family: var(--font-montserrat);
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.horizontalSeparator {
  border-bottom: var(--cell-border);
}

.verticalSeparator {
  border-right: var(--cell-border);
}

.whiteColor {
  color: var(--white-color);
}

::-webkit-scrollbar {
  width: 0.8rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--text-color-main);
  border-radius: 0.4rem !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--accent-color);
  border-radius: 0.4rem !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--accent-color-dark);
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.height-1 {
  height: 100%;
}

.height-2 {
  height: 50%;
}

.width-1 {
  width: 100%;
}

.width-2 {
  width: 50%;
}

.hide {
  visibility: hidden;
}

.show {
  visibility: visible;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media screen and (max-width: 450px) {
  html {
    /*font-size: 2.55vw;*/
    font-size: 1.83vw;
  }
}

.lngHandle {
  cursor: pointer;
}
.lngHandle.show > div.show {
  display: block;
}

.lngHandle .dropdown-toggle {
  border-radius: 1.2rem;
  cursor: pointer;
}

.lngHandle .dropdown-toggle:hover,
.lngHandle .dropdown-toggle:focus {
  background: var(--background-secondary);
}
/* 
.lngHandle .dropdown-item {
  width: 160px;
} */

.lngHandle .dropdown-item:hover,
.lngHandle .dropdown-item:focus {
  color: var(--black-color);
  background: var(--accent-color);
}
