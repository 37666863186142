.box {
    text-align: center;
    padding: 2.3rem 0.6rem 2.3rem 2.3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--text-color-secondary);
}

.row1 {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0.5rem;
    font-size: 1.2rem;
    margin-left: 0.1rem;
}


.total {
    flex: 1;
    text-align: left;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 0.9rem;
    margin-top: 1.3rem;
}

.header {
    font-size: 2.29824rem;
    font-weight: 300;
    font-family: Montserrat;
    color: var(--black-color);
}

.internalBox {
    width: 100%;
    box-shadow: var(--box-shadow);
    border-radius: 0.5rem;
    background-color: var(--token-box-bg);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    height: 4.1rem;
    font-size: 1.5rem;
    font-weight: 300;
    color: var(--black-color);
    margin-top: 0.89856rem;


}