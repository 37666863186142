.container {
    position: absolute;
    width: 31.5rem;
    top: 4rem;
    right: -3.97rem;
    z-index: 3;
}

.arrow {
    display: flex;
    height: 1.14rem;
    justify-content: right;
    margin-right: 2.85rem;
}

.img {
    height: 100%;
}

.content {
    height: 10.1rem;
    width: 100%;
    border-radius: 0.5rem;
    border: 0.1rem solid var(--accent-color);
    background-color: var(--help-bg);
    color: var(--white-color);
    display: flex;
    justify-content: start;
    padding-left: 2.9rem;
    padding-right: 1.52rem;
    flex-direction: column;
}

.contentTranslated {
    composes: content;
    height: 13rem;
}

.txt1 {
    margin-top: 0.2rem;
    ;
    font-size: 1.7rem;
    font-weight: 600;
}

.txt2 {
    margin-top: 0.9rem;
    ;
    font-size: 1.4rem;
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

.number {
    color: var(--green-border);
}

.xmark {
    height: 1.1rem;
    fill: var(--accent-color);
    width: 1.1rem;
    align-self: end;
    cursor: pointer;
    margin-top: 1.4rem;
}

@media screen and (max-width: 450px) {
    .container{
        right: 0;
        top: 4.5rem;
    }
}