.bg {
    color: var(--text-color-secondary);
    font-size: 10rem;
    font-weight: 600;
    opacity: 0.1;
    position: absolute;
    top: 3.5rem;
    left: 9rem;
}

.section {
    height: 23rem;
    padding: 10rem 10rem 0 10rem;
}

.row1 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.row2 {
    margin-top: 0.8rem;
    color: var(--accent-color);
    font-size: 1.5rem;
}

.title {
    color: var(--text-color-secondary);
    font-size: 3.5rem;
    font-weight: 600;
}

.bg-earning {
    composes: bg;
    left: 6.6rem;
}

.section-earning {
    composes: section;
    padding: 10rem 10rem 0 7.1rem;
}

@media screen and (max-width: 450px) {
    .bg {
        left: 3rem;
        top: 2rem;
        font-size: 7rem;
    }
    .section {
        padding-left: 3.8rem;
        padding-right: 7rem;
        padding-top: 7rem;
        height: 17rem;
    }
    .title {
        font-size: 2rem;
    }
    .row2 {
        font-size: 1.4rem;
    }
}