.container {
    color: var(--text-color-main);
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
}

.h1 {
    display: flex;
    justify-content: left;
    gap: 0.8rem;
    align-items: center;
    font-size: 2rem;
    padding-left: 13rem;
    padding-right: 9rem;
}

.h2 {
    display: flex;
    justify-content: left;
    gap: 0.8rem;
    align-items: center;
}

.h3 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
}

.logo1 {
    width: 4.2rem;
    height: 4.2rem;
    border: 0.05rem solid var(--white-color);
    border-radius: 2.1rem;
    background-color: var(--background-primary-light);
    padding: 0.7rem;
}

.logo {
    width: 3.1rem;
    height: 3.1rem;
    border: 0.05rem solid var(--white-color);
    border-radius: 2.1rem;
    background-color: var(--background-primary-light);
    padding: 0.7rem;
}

.item {
    display: flex;
    align-items: center;
    gap: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.selectedItem {
    border: 0.05rem solid var(--accent-color);
}

.item1 {
    width: 3rem;
}

.item2 {
    width: 14rem;
}

.item3 {
    width: 7.5rem;
    text-align: right;
}

.item4 {
    width: 17.5rem;
    margin-left: 3rem;
}

.expandIconParent {
    display: none;
}

.upgrade {
    height: 3.6rem;
    background: var(--background-green-gradient);
    border-radius: 1rem;
    font-size: 1.6rem;
    color: var(--white-color);
    border: none;
    cursor: pointer;
    width: 100%;
}

.itm {
    display: flex;
    align-items: center;
    height: 3.6rem;
    padding-left: 13rem;
    padding-right: 9rem;
}
.tbheader{
    padding-left: 13rem;
    padding-right: 9rem;
}
.itm:nth-child(odd) {
    background: var(--background-primary-main);
}

@media screen and (max-width: 450px) {
    .h1{
        padding-left: 4.5rem;
        padding-right: 4.5rem;
    }
    .item {
        gap: 2rem;
    }
    
    .itm,.tbheader{
        padding-left: 4.5rem;
        padding-right: 4.5rem;
    }

    .item4 {
        width: 13rem;
    }

    .expandIcon {
        width: 2.6rem;
        stroke: var(--white-color);
        cursor: pointer;
    }

    .expandIconParent {
        display: block;
        flex: 1;
        text-align: right;
    }
}