.container {
    width: 100%;
    height: 100%;
    padding: 2.5rem 5rem;
    display: flex;
    flex-direction: column;
    color: var(--text-color-secondary);
    background-color: var(--background-primary-light);
    position: relative;
}

.bg {
    color: var(--text-color-secondary);
    font-size: 8.75rem;
    font-weight: 600;
    opacity: 0.05;
    position: absolute;
    top: 0;
    left: 3.5rem;
}

.row1 {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0.5rem;
    font-size: 1.3rem;
}



.row2 {
    margin-top: 7.8rem;
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    z-index: 2;
}

.col21 {
    width: 25rem;
}

.tab {
    width: 22rem;
    display: flex;
    flex-direction: column;
}

.tabTitle {
    width: 22rem;
    display: flex;
    justify-content: space-around;
    flex: 1;
}

.tabLine {
    width: 50%;
    height: 0.4rem;
    background-color: var(--accent-color);
    border-radius: 0.4rem;
    margin-left: 0;
    transition: all 600ms;
}

.tab2Selected {
    margin-left: 50%;
}

.tabItem {
    width: 50%;
    text-align: center;
    font-weight: 300;
    cursor: pointer;
}

.tabItemSelected {
    font-weight: 600;
}

.r3 {
    margin-top: 1.2rem;
    color: var(--accent-color);
    font-size: 1.5rem;
}

.row4 {}

.row5 {
    height: 6.4rem;
    display: flex;
    margin-left: 3.3rem;
    font-size: 1.5rem;
    color: var(--white-color);
    justify-content: center;
    align-items: center;
}

.circleDownIcon {
    height: 3.2rem;
    width: 3.2rem;
    fill: var(--accent-color);
    cursor: pointer;
}

.row6 {}

.row7 {
    display: flex;
    margin-top: 4.1rem;
    font-size: 1.3rem;
    color: var(--text-color-secondary);
    padding-left: 2rem;
    padding-right: 2rem;
}

.icon {
    width: 1.6rem;
    fill: var(--accent-color);
    margin-left: 0.7rem;
}

.row8 {
    display: flex;
    margin-top: 1.1rem;
    font-size: 1.3rem;
    color: var(--text-color-secondary);
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;
}

.row9 {
    display: flex;
    margin-top: 4.3rem;
    gap: 2.7rem;
    justify-content: center;
}

.inputContainer {
    margin-top: 4.5rem;
    display: flex;
    flex-direction: column;
}


.link {
    cursor: pointer;
}

.mglnkParent {
    margin-top: 3.4rem;
    display: flex;
    justify-content: center;
}

.mglnk {
    color: var(--text-color-main);
    font-size: 1.7rem;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
}

@media screen and (max-width: 450px) {
    .container {
        padding: 2.5rem;
    }
    .bg {
        font-size: 8.5rem;
    }
}