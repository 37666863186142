.container {
    width: 100%;
    height: 17rem;
    background-image: url('/src/_customization/assets/fee-bg2.jpg');
    background-repeat:no-repeat;
    background-size: 100% 100%;
    padding: 1.4rem 5rem;
    overflow: hidden;
    position: absolute;
    bottom: 0;
}

.title {
    font-size: 2.7rem;
    font-weight: bold;
    color: var(--white-color);
}
.titleColor{
    color: var(--accent-color);
}
.readMore{
    color: var(--white-color);
    text-decoration: underline;
    font-size: 1.5rem;
    font-weight: 600;
    cursor: pointer;
}
.r1{
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 3rem;
}
@media screen and (max-width: 450px) {
    .container {
        position: unset;
    }
}