.container {
    width: 100%;
    height: 100%;
    padding: 2.5rem 5rem 0 5rem;
    display: flex;
    flex-direction: column;
    color: var(--text-color-secondary);
}

.row1 {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0.5rem;
    font-size: 1.3rem;
}

.title {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tokens {
    height: 5.8rem;
    width: 10rem;
    position: relative;
    
}

.tokenIcon1 {
    position: absolute;
    width: 5.8rem;
    height: 5.8rem;
    border: 0.1rem solid var(--white-color);
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: var(--white-color);

}

.tokenIcon2 {
    position: absolute;
    width: 5.8rem;
    height: 5.8rem;
    border: 0.1rem solid var(--white-color);
    top: 0;
    right: 0;
    border-radius: 50%;
    background-color: var(--white-color);
}

.val1 {
    font-size: 3rem;
    font-weight: 600;
    color: var(--text-color-secondary);
}

.icon {
    width: 3rem;
    fill: var(--accent-color);
    margin-right: 2rem;
    cursor: pointer;
}

.row2 {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 1rem;
    font-size: 1.7rem;
    font-weight: 600;
    color: var(--text-color-secondary);
    margin-top: 0.6rem;
}

.green {
    color: var(--positive-growth);
}
.red{
    color: var(--negative-growth);
}

.row3 {
    font-size: 1.2rem;
    color: var(--selector-bg);
    margin-top: 0.8rem;
}

.chart {
    height: 100%;
    color: var(--black-color);
}

.chartLabel {
    fill: var(--white-color);
    font-size: 1.2rem;
}

.row4 {
    margin-top: 2rem;
    flex: 1;
    position: relative;
}

.selectPeriod {
    z-index: 2;
    width: 20rem;
    height: 3.8rem;
    border: 0.05rem solid var(--accent-color);
    border-radius: 2rem;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

.periodItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    color: var(--accent-color);
    font-size: 1.3rem;
    font-weight: 600;
}

.selectedPeriodItem {
    background-color: var(--accent-color);
    border-radius: 1.9rem;
    color: var(--text-on-accent);
}

@media screen and (max-width: 450px) {
    .container {
        padding: 2.5rem;
        height: 65rem;
    }
    .selectPeriod {
        height: 3rem;
    }
}