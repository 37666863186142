.modalBackground {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--modal-bg);
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.modalContainer {
    width: 70.5rem;
    height: 42.3rem;
    border-radius: 1.2rem;
    border: 0.001rem solid var(--accent-color);
    background: var(--help-bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.6rem;
    padding: 5.15rem;
}

.aboveTitle {
    color: var(--white-color);
    font-size: 1.2rem;
}

.title {
    color: var(--white-color);
    font-size: 3rem;
    font-weight: bold;
}

.body {
    color: var(--white-color);
    font-size: 1.2rem;
    line-height: 1.75rem;
    text-align: justify;
}

.bodySpan {
    color: var(--white-color);
    font-size: 1.2rem;
    line-height: 1.75rem;
}

.footer {
    display: flex;
    gap: 1.4rem;
    margin-top: 0.5rem;
}

.btnAccept {    
    background-image: var(--btn-bg-image);
    background-color: var(--btn-bg-color);
    border-color: transparent;
    color: var(--text-on-accent);
    width: 16rem;
    border-radius: 1.1rem;
    padding: 1rem;

}

.btnDecline {
    background-color: transparent;
    color: var(--white-color);
    width: 16rem;
    border-radius: 1.1rem;
    padding: 1rem;
}

.btnDecline a{
    cursor: default;
}

.btnText {
    font-size: 1.5rem;
}

@media screen and (max-width: 450px){
    .modalContainer {
        margin: 0 1rem;
    }
}

@media (min-width: 451px) and (max-width: 1024px) {
    .modalContainer {
        width: 75.5rem;
    }
    .body {
        line-height: 2rem;
    }

}

