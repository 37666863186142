.container {
    height: 6.3rem;
    display: flex;
    align-items: center;
    justify-content: right;
    color: var(--white-color);
    font-size: 1rem;
    font-weight: 500;
    padding-right: 9rem;
}

.sp {
    height: 80%;
    margin-left: 3.5rem;
    margin-right: 3.5rem;
}

.pwbyimg {
    height: 1.42rem;
    margin-left: 1.05rem;
}

.terms {
    flex: 1;
    padding-left: 8rem;
    font-size: 1.4rem;
}

@media screen and (max-width: 450px) {
    .container {
        padding-left: 2rem;
        padding-right: 2rem;
        justify-content: left;
    }

    .pwbyimg {
        height: 1.1rem
    }

    .sp {
        margin-left: 2.2rem;
        margin-right: 2.2rem;
    }

    .terms {
        padding-left: 0
    }
}