.container {
    min-height: calc(100vh - 15rem);
    position: relative;
}

.bg {
    color: var(--text-color-secondary);
    font-size: 10rem;
    font-weight: 600;
    opacity: 0.1;
    position: absolute;
    top: 3.7rem;
    left: 6.6rem;
}

.sec {
    height: 23rem;
    padding: 10rem 10rem 0 7.1rem;
}

.rs1 {
    color: var(--text-color-secondary);
    font-size: 3.5rem;
    font-weight: 600;
}

.rs2 {
    margin-top: 0.8rem;
    color: var(--accent-color);
    font-size: 1.5rem;
}

.header {
    height: 17.9rem;
    padding-top: 7.4rem;
    padding-left: 7.1rem;
    display: flex;
    gap: 14rem;
}

.h1 {
    color: var(--text-color-secondary);
    font-size: 1.3rem;
}

.h2 {
    color: var(--accent-color);
    font-weight: 700;
    font-size: 2rem;
    margin-top: 1.2rem;
}

.row {
    padding-bottom: 4.3rem;
    padding-top: 4.3rem;
    padding-left: 5.7rem;
    padding-right: 10.2rem;
    color: var(--white-color);
    font-size: 2rem;
}

.row:nth-child(even) {
    background: var(--background-primary-main);
}

.row:nth-child(odd) {
    background: var(--background-primary-dark)
}

.rowHeader {
    height: 7.1rem;
    display: flex;
    align-items: center;
}

.tokenIcon {
    height: 7.1rem;
    width: 7.1rem;
    margin-left: 1.5rem;
}

.tokenName {
    font-size: 2.9rem;
    font-weight: 600;
    margin-left: 3.1rem;
}

.apy {
    font-size: 2rem;
    font-weight: 300;
    margin-left: 4.2rem;
}

.section {
    margin-top: 2.4rem;
    display: flex;
    align-items: stretch;
    gap: 3rem;
}

.section1 {
    width: 36.85rem;
    border-right: var(--cell-border);
}

.section2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5.15rem;
    justify-content: center;

}

.section3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
}

.sp {
    height: 18.2rem;
    background-color: var(--text-color-main);
    width: 0.05rem;
}

.tabTiltles {
    display: flex;
    font-size: 2rem;
    height: 2.4rem;
    gap: 7.7rem;
    margin-left: 1.5rem;
    margin-bottom: 1.57rem;
    cursor: pointer;
    font-weight: 300;
}

.tabTiltleSelected {
    font-weight: 600;
}

.tabLine {
    height: 0.4rem;
    width: 10.7rem;
    background-color: var(--accent-color);
    border-radius: 0.4rem;
    transition: all 600ms;
}

.tab2Selected {
    margin-left: 16.4rem;
}

.actionTitle {
    margin-top: 1.8rem;
    margin-left: 1.5rem;
    color: var(--text-color-main);
    font-size: 1.3rem;
    font-weight: 400;
}

.inputContainer {
    height: 6rem;
    background-color: var(--text-color-main);
    border-radius: 0.5rem;
    margin-top: 0.6rem;
    margin-right: 3.2rem;
    margin-left: 0;
    position: relative;
    overflow: hidden;
}

.input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    color: var(--background-secondary);
    padding-left: 1.5rem;
    padding-right: 15.5rem;
    font-size: 2rem;
    font-weight: 700;
}

.input:focus {
    outline-width: 0;
}

.btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 11rem;
    background-color: var(--btn-bg-color);
    background-image: var(--btn-bg-image);
    color: var(--text-on-accent);
    font-size: 1.6rem;
    font-weight: 500;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}
.btn:disabled {
    background-color: var(--btn-bg-dis);
    cursor: default;
}
.maxBtn {
    position: absolute;
    top: 1.5rem;
    right: 11.2rem;
    width: 5rem;
    background-color: var(--accent-color);
    color: var(--text-on-accent);
    font-size: 1.6rem;
    font-weight: 500;
    height: 3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
}


.avlblnc {
    color: var(--text-color-main);
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-left: 1.5rem;
}

.row1 {
    display: flex;
    gap: 3.5rem;
}

.val {
    width: 30rem;
    display: flex;
    align-items: center;
}

.cl {
    width: 26rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 0;
    flex-shrink: 0;
}
.ct {
    font-size: 2rem;
    font-weight: 300;
    color: var(--white-color);
    text-align: left;
}
.cVal{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.v1 {
    font-size: 2rem;
    font-weight: 600;
    color: var(--white-color);
}
.v2 {
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--text-color-main);
}


.c3 {
    width: 42rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.c31 {
    width: 19rem;
    font-size: 1.8rem;
    font-weight: 300;
    color: var(--white-color);
    text-align: left;
}

.c32 {
    width: 17rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--white-color);
    text-align: right;
    
}

.c33 {
    width: 5rem;
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--text-color-main);
    text-align: left;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
}
.c34 {
    margin-left: 19rem;
    width: 17rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--white-color);
    text-align: right;
}

.reward {
    width: 42rem;
    height: 6rem;
    background-color: var(--text-color-main);
    overflow: hidden;
    display: flex;
    border-radius: 0.5rem;
    font-size: 2rem;
    font-weight: 600;
    color: var(--background-secondary);
    align-items: center;
    padding-left: 1.5rem;
}

.rewardVal {
    flex: 1;
    margin-left: 1.8rem;
}

.claim {
    width: 11rem;
    background-color: var(--btn-bg-color);
    background-image: var(--btn-bg-image);
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--text-on-accent);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    border: none;
}
.claim:disabled {
    background-color: var(--btn-bg-dis);
    cursor: default;
}

.tokenIcon3 {
    width: 4.7rem;
    height: 4.7rem;
    margin-right: 0.9rem;
}

.vsp {
    display: none;
}
.expandIconParent{
    display: none;
}
.loading{
    height: 4rem;
}
.loadingParent{
    margin-top: 2rem;
    text-align: center;
}
@media screen and (max-width: 450px) {
    .tab2Selected {
        margin-left: 13.7rem;
    }
    .bg {
        font-size: 7rem;
        top: 4.2rem;
        left: 2.3rem;
    }
    .rs1 {
        font-size: 2rem;
    }
    .rs2 {
        font-size: 1.4rem;
    }

    .sec {
        padding: 9rem 5.6rem 2.8rem 3.2rem;
        height: auto;
    }
    .header {
        padding: 0;
        gap: 0;
        height: auto;
        border: var(--cell-border)
    }

    .h1 {
        flex: 1;
        padding: 2rem 3.2rem 2rem 3.2rem
    }

    .h2 {
        font-size: 1.8rem;
    }

    .vsp {
        border-right: var(--cell-border);
        display: block;
    }

    .row {
        padding-left: 3.2rem;
        padding-right: 5.6rem;
    }
    .collapsedRow {
        padding: 1.5rem 5.6rem 1.5rem 3.2rem;
    }
    .expandIcon {
        width: 2.6rem;
        stroke: var(--white-color);
        cursor: pointer;
    }
    .expandIconParent {
        display: block;
        flex: 1;
        text-align: right;
    }

    .section {
        flex-direction: column;
    }
    .section1{
        width: 100%;
        border-right: none;
    }
    .inputContainer{
        margin-right: 0;
    }
    .reward{
        width: 100%;
        font-size: 1.4rem;
    }
    .c3{
        width: 100%;
    }
    .c33{
        font-size: 1rem;
    }
    .tabTiltles{
        font-size: 1.57rem;
    }
    .btn,.claim{
        font-size: 1.85rem;
    }
    .tokenIcon{
        width: 6.14rem;
        height: 6.14rem;
    }
    .tokenName{
        font-size: 2.25rem;
    }
    .apy{
        font-size: 1.54rem;
    }
    .cl{
        width: calc(50% - 1.75rem);
    }
}